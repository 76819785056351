.BillBord {
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.814);
  min-width: 60vw;
  min-height: 80%;
  max-height: 95vh;
  margin: auto;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  overflow: auto;
  overflow-x: hidden;
}
.backGroundLogo {
  /* background-image: url(../../assets/icon_stor.png); */
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -999;
  height: 100%;
  width: 100%;
}
.DialogWindow {
  background-color: rgba(0, 153, 153, 1);
}
.headerText {
  flex: wrap;
}
.PrimaryTextColor {
  color: rgba(0, 0, 0, 0.7);
  flex: wrap;
  text-align: center;
}
.AlertBox {
  background-color: rgba(244, 23, 32, 0.6);
  width: 25vw;
  height: 25vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ThumbUp {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: fixed;
  z-index: 100;
}

.AlertBoxSuccess {
  background-color: rgba(12, 112, 12, 0.6);
  border-radius: 10px;
  box-shadow: darkblue;
  min-width: 25vw;
  min-height: 25vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  overflow: auto;
}
.inputFields {
  color: white !important;
}
.MuiInputBase-input {
  color: 'white';
}
.Banner {
  background-color: rgba(255, 0, 0, 0.5);
  width: 70%;
  height: 40%;
  border-radius: 20px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 40%;
  margin-top: -50px;
}
.inputField {
  font-size: 30 !important;
}


.headerContainer{
  height: 50vh;
  padding: 0;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.headerContainer:before {
  background-color: rgba(0, 0, 0, 0.3);
}
.headerContainer:after, .headerContainer:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
}

.headerContainer .headerContainer-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.headerContainer .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}

.headerContainer .container {
  color: #FFFFFF;
}
.headerContainer .container {
  height: 100%;
  z-index: 1;
  text-align: center;
  position: relative;
}

.NavItems{
  margin-left: 10;
  margin-right: 10;
}
.BackGroundBox{
  background-color: rgba(246,246,246,1);
  border-radius: 20px;
  max-width: 80vw;
  min-width: 80vw;
  margin:auto;
  margin-top:30px;
  margin-bottom:30px;
  padding:30px

}
.Main{
  width: 100%;
  justify-content:center;
  align-items: center;
  align-self: center;
}
@media (min-width: 600px) {
  .headerContainer{
  height: 100vh;
  }
}