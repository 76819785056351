/* .App {
  text-align: center;
  background-image: url(./assets/Blaavegen.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  color: white;
}
h1,
h2,
h3,
h4,
h5 {
  color: white;
}
.main {
  padding:10;
  min-height:'100%';
}

@media only screen and (max-width: 850px) {
  .BillBord {
    width: 80%;
    min-height: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .BillBord {
    width: 95%;
    min-height: 100%;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1em;
  }
  h4 {
    font-size: 0.7em;
  }
  .HsbLogo {
    width: 70%;
  }
  .SgLogo {
    height: 30%;
  }
}
 */

@media only screen and (max-width: 650px) {
  .page-header {
    max-height: none;
  }
}

.FrontPageLogo {

  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.footerText {
  font-size: 100%;
}
.ConfirmationBox {
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.headText {
  font-weight: bold;
}
